@import (reference) url("../vars.less");

@form-field-border-color-focus: #FF4E00;
@form-field-border-radius: 16px;

body {
	margin: 0;
	overflow-y: hidden;
}

a {
	color: #4e4a46;
}

textarea {
	min-height: 4rem;
	max-height: 300px !important;
}

#app {
	overflow-y: hidden;
}

.actionBar {
	background-color: #000;
	width: 100%;
	height: 4em;
	color: #fff;
	text-align: center;
	padding: .5em;
	position: fixed;
	z-index: 999;
}

.mainMenu > div.menu > ul > li > a .icon, .mainMenu > div.menu > ul > li > span .icon {
	color: #ffffff !important;
}

.mainMenu > div.menu.shown {
	background: #000;
}

label {
	display: block !important;
	text-align: left;
	//padding-left: 0.2em;
	padding-top: 0.3em;
	margin-bottom: 0 !important;
	font-weight: 600;

	i {
		margin-left: 0.5rem;
	}
}

.ui-dialog-content {
	padding: .5em;
}

.form {

	div.subform {
		display: flex;
		justify-content: space-around;

		div.subformColumn {
			width: 98%;
		}
	}

	min-height: 4em;

	button.btn-primary {
		border-color: #ccc;
		background-color: #fff;
	}
}

//noinspection SpellCheckingInspection
.mobileOwnerWrapper {
	display: flex;
	justify-content: space-around;
	align-items: center;

	div.form-group {
		width: 20%;

		&:first-child {
			width: 80%;
		}

		#ownerperm {
			label {
				font-size: 1.5em;
			}
		}

	}


}

.ui-helper-hidden-accessible {
	display: none;
}

.autocompleteTitle {
	margin-top: 3px;
	font-weight: bold;
	background-color: #252525;
	margin-bottom: 3px;
	line-height: 20px;
	text-align: center;
	color: white;
}

.ui-autocomplete-category {
	font-weight: 600;
	font-size: .9em;
	color: #111111;
	text-align: left;
	padding: 3px;
	border-top: solid 1px #999999;
	list-style: none;

	&:first-child {
		border-top: solid 1px transparent;
	}
}


.ui-autocomplete {
	//.border( @color: @form-field-border-color-focus);
	//.border-radius(@form-field-border-radius);
	border-radius: 0 0 @form-field-border-radius @form-field-border-radius !important;
	overflow: hidden;
	//max-width: 100%;
	padding: 0.5em !important;
	max-width: 95% !important;
	background: #fff !important;
	box-shadow: 0 0.1em 0 0.2rem rgba(255, 165, 0, 0.25) !important;
	border: 1px solid #ffd280 !important;
	border-top: none !important;
	list-style: none;

	.firmItem {
		display: block;
		max-width: 320px;

		span.firmIdNumber {
			display: block;
			float: right;
			width: 60px;
			color: #333;
			font-size: 11px;
			line-height: 16px;
			text-align: right;
			font-weight: 500;
		}

		span.firmName {
			display: block;
			float: none;
			padding-left: 4px;
			//width: 200px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #000;
			font-weight: 300;
		}
	}

	.ui-menu-item {
		cursor: pointer;

		&.ui-state-focus {
			font-weight: inherit;
			color: @form-field-border-color-focus !important;

			span.firmIdNumber {
				color: @form-field-border-color-focus;
			}

			span.firmName {
				color: @form-field-border-color-focus;
			}
		}
	}
}

input.error {
	border-color: red !important;
	color: red !important;
	box-shadow: red 0 0 5px !important;
}

fieldset.subformGroup {
	//display: flex;

	& > div:first-child {
		width: 100%;
	}
}

div.field_contact {
	text-align: left;

	span.contactField {
		span.selectCountry {
			button {
				height: 38px !important;
				display: inline-flex;

				& > span.buttontext {
					display: none;
				}
			}
		}

		input {
			width: 65vw;
		}
	}
}


span.field {
	input {
		width: 85%;
		//float: left;
	}

	button {
		border-color: #ccc;
	}

	span.selectCountry {
		min-width: 4.1em;
	}


	a {
		width: 20% !important;
		float: left;

	}

}

.layoutMenu.clf {
	display: none;
}

.assignSelect {
	display: flex;
	align-items: center;
	//width: 100%;
	justify-content: flex-start;
	margin-bottom: .5em;
	flex-grow: 1;

	select {
		min-width: 50%;
	}

	.assignText {
		display: flex;
		flex-grow: 1;
	}

	.select2-container--bootstrap, .select2-container--bootstrap4 {
		min-width: 250px;
		max-width: 70% !important;
	}

	.select2-container--bootstrap4 .select2-selection__clear, .select2-container .select2-selection--single .select2-selection__clear {
		margin-top: 0.5rem;
		width: 1em;
		font-size: 1.3rem;
	}
}

.select2-container--open {
	//left: 0 !important;
	//margin-left: 10px !important;
	//width: ~"calc(100vw - 20px)";
	//width: 100% !important;
	//height: 100% !important;
	//top:0 !important;
	//left: 0 !important;
	//position: fixed !important;

	span.select2-dropdown {
		width: 100% !important;
		//height: 100%;
		position: absolute !important;
		//display: flex;
		/* justify-content: center; */
		//align-items: center;
		//top: 0;
		//animation: ease-in-out show .5s;
		z-index: 9999;

		box-shadow: 0px 0px 50px 40px rgba(0, 0, 0, .6);

		span.select2-results {
			ul.select2-results__options {
				max-height: 70vh;
				overflow-y: auto;

				li.select2-results__option {
					line-height: 2em;
				}
			}
		}

		span.select2-dropdown-buttons {
			position: fixed;
			bottom: 0;
			padding: 1em;
		}

		span.select2-search--dropdown {
			//position: fixed;
			//top: 0;
			//width: 100%;
			//line-height: 2em;
		}
	}
}

.contactItem {
	display: flex;
	justify-content: end;
	margin: 0 auto;

	div {
		//background-color: #ddd;
		//border: 1px solid #fff;
		border-bottom: 1px solid #eee;
		//border-radius: .3em;
		padding: .3em;
		display: flex;
		justify-content: center;
		align-items: center;

		&:first-child {
			width: 100%;
		}
	}
}

.contactItemMenu {
	display: flex !important;
	justify-content: space-between !important;

	a {
		font-size: 1.5em;
		padding-right: .2em;
		padding-left: .2em;
	}
}

.personBox {
	margin: 1em 0 1em 0;

	.personName {
		background-color: #444;
		color: #fff;
		font-weight: 800;
		font-size: .8rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: .2em;

		.title {
			width: 90%;
		}

		.menuItem {
			display: flex;
			align-self: center;
			font-size: 1.5em;
		}
	}

}

span#repeat_entry {
	display: flex;
	align-items: center;

	.select2.select2-container.select2-container--bootstrap, .select2.select2-container.select2-container--bootstrap4 {
		width: 100% !important;
	}
}

.fieldDuration {
	display: block;
	align-items: center;

	.dash {
		display: block;
		text-align: center;
	}
}

.orderFilter {
	display: flex;
	justify-content: center;
}

@keyframes show {
	from {
		width: 0 !important;
		height: 0;
	}
	to {
		width: 100% !important;
		height: 100%;
	}
}

#form_hint {
	display: none !important; //TODO jp  až se vymyslí jak je zobrazovat tak změnit
	left: 0 !important;
	color: green;
	top: -5px !important;
	font-size: 12px;
	text-align: left;
}

.listItem {
	background-color: #fff;
	height: @listItemHeight;
	font-size: 0.8em;
	line-height: 100%;
	display: flex;
	justify-content: space-between;
	padding: .1em;
}

.listItemContentWrapper {
	width: 95%;
	color: #595959;
	display: inline-flex;
	padding: .2em;

	.itemContentLeft {
		width: 60%;
		text-align: start;

		& > div {
			margin: .3em;
		}

		& > span {
			margin: .3em;
		}
	}

	.itemContentRight {
		width: 40%;
		text-align: end;

		& > div {
			margin: .3em;
		}

		& > span {
			margin: .3em;
		}
	}
}

.itemBasic {
	//border-bottom: 1px solid #ddd;
	padding: 5px;
	display: flex;
	justify-content: space-between;
}

.itemFirm {
	//flex: 0 0 70%;
}

.itemDateNextAction {
	flex: 0 0 25%;
}

.itemState {
	//color: #fff;
	//border: 1px solid #ddd;
	//border-radius: 4px;
	font-weight: 600;
	//	box-shadow: 1px 1px 2px #ddd;
	//	height: 2em;
}

.itemName {
	font-size: 0.9rem;
	color: #4e4a46;
	font-weight: 600;
	text-align: left;

	a {
		color: #1262a7;
	}
}

.itemMenu {
	font-size: 2.5em;
	color: #9c9c9c;
	flex: 0 0 5%;
	padding-left: 5px;
	text-align: center;
	vertical-align: baseline;
	display: flex;
	justify-content: start;
	align-items: center;
	cursor: pointer;

	> .menu {
		width: 30vh;
		display: none;
		z-index: 1;
		background-color: #fff;
		position: absolute;
		text-align: center;
		font-size: 0.7em;
		border: 1px solid #ccc;
		border-radius: 3px;
		box-shadow: 1px 3px 3px #ccc;
		right: 1.5em;
		color: #000;
		top: .2em;

		> span {
			cursor: pointer;
			line-height: 1.8em;
		}
	}
}

.itemExpand {
	display: none;
	flex: 0 0 5%;
}

.itemDescription {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

}

.entityInfoContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.8rem;
	border-bottom: 1px solid #eeeeee;
	margin-bottom: 0.8em;
	//background-color: rgb(255, 240, 213);
	.ownerSummary {
		display: grid;
		align-items: center;
		width: 80vw;

		.ownerItem {
			display: flex;
			justify-content: space-between;
			align-items: center;
			max-width: 80vw;

			span.ownerLabel {
				padding-right: 0.5em;
				font-weight: 600;
			}

			span.userMe i {
				padding-right: .3em;
			}
		}
	}

	#editPermissionButton {
		display: none;
	}
}

.select2-dropdown.select2-dropdown--below.form-control {
	min-height: 12em;
}

//span.select2-container.select2-container--bootstrap.select2-container--open.priority_dropdown_container {
//	width: 80%;
//}

span.select2-container--bootstrap .select2-selection--single {
	height: 40px !important;
	border: 1px solid #ff4e00;
	z-index: 100000;
	position: relative;
	background-color: white;

	.select2-selection__rendered {
		padding: 0.4rem 6px;
	}
}

#mobileForm, #mobileFilterForm {
	background-color: #fff;
	padding: 10px;
}

.formGroup > h4 {
	display: none;
}

.subformColumns1 {
	width: 94%;
	float: left;
	padding-bottom: 0.2rem;
}

.subfilterform {
	.subformColumns1 {
		width: auto;
		float: none;
		padding-bottom: 0.2rem;
	}
}


span.field {
	display: flex;
	justify-content: space-between;
}

.datetimeRange {
	display: flex;
	align-items: center;

	.relSwitch {
		width: 50%;
	}
}

.fieldRange {
	display: flex;
	align-items: center;
}

.styled-radio + .label-text::before, .styled-checkbox + .label-text::before {
	border-radius: 4px !important;
}

div.add_field {
	text-align: left;
	margin-top: 5px;

	a.add_field {
		background-color: #41A042;
		color: white;
		text-shadow: none;
		padding: 5px 10px;
		border-radius: .3em;
		cursor: pointer;
	}
}

.addField {
	background-color: #41A042 !important;
	color: white !important;
	text-shadow: none;
	//border: 1px solid green;
	padding: 5px 10px;
	border-radius: .3em;
	cursor: pointer !important;
}

div.delete_field {
	width: 5%;
	float: right;
	margin-bottom: 0.3em;
}

div.subform > div.asform {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	width: 100%;
}

a.delete_field {
	color: #a91818;
	padding: 4px;
	cursor: pointer;
	font-size: 1.2em;
	float: right;
}

legend {
	display: none;
}


.mysqlSubobjectField {
	.form-group {
		min-height: 1em;

		button.deleteField {
			float: right;
			color: #fff;
			background-color: #a91818;
		}
	}

	span.title {
		line-height: 3em;

	}
}

.newEntityDialog.ui-dialog-content.ui-widget-content {
	padding: 0.5em;
	margin-top: 5em;
}

.ui-dialog.ui-corner-all.ui-widget {
	.ui-dialog-buttonpane {
		padding: 20px;
		text-align: center;
		.ui-button{
			margin: 0 20px
		}
	}

	#confirm_message {
		margin-top: 72px;
		padding: 15px 5px;
	}

}

.ui-dialog-titlebar.ui-widget-header.ui-corner-all.ui-helper-clearfix.ui-draggable-handle:not(.user_message) {
	display: flex;
	justify-content: space-between;
	height: 3em;
	padding: .3em;
	align-items: center;
	background-color: #000;
	color: #fff;
	margin-bottom: 0.5em;
	font-size: 1.5em;
	position: fixed;
	width: 100%;
	z-index: 9999;

	span.ui-dialog-title {
		margin-left: 2em;
	}

	button.ui-dialog-titlebar-close {
		span.ui-button-text {
			display: none;
			content: none;
		}

		&::before {
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			font-weight: 1000;
			color: #ff6f6f !important;
			font-size: 1.5em;
			content: "\2716";
		}

		color: #ff6f6f !important;
		border: none;
		background-color: transparent;

	}
}

.select2-container .select2-selection--single .select2-selection__clear {
	font-size: 1.1rem;
	color: #000;
	background-color: transparent;
}

span.priceFieldLayout {
	display: flex;
	justify-content: space-between;
	align-items: baseline;

	.priceField {
		width: 60% !important;
	}

	.select2-container {
		width: 40% !important;
	}
}

.subforms {

	.select2-container .select2-selection--single .select2-selection__clear {
		display: none;
	}

	label {
		display: none !important;
	}

	.subform:first-child {
		label {
			display: block !important;
		}
	}
}

div.select {
	position: absolute;
	left: 10px;
	width: calc(100vw - 20px);
	display: none;

	.select2.select2-container.select2-container--bootstrap.select2-container--below {
		width: auto !important;
	}
}

button {
	span.flag {
		float: left;
		display: inline-block;
		width: 24px;
		height: 21px;
		margin-right: 5px;
		background-position: center left;
		background-repeat: no-repeat;
	}

	&.btn-primary {
		color: #212529;
		background-color: #fff;
		border-color: #ced4da;

		&.submit {
			background-color: #fc8c3f;
			color: #fff;
			border-color: transparent;
		}

		&.delete {
			background-color: #ce3131;
			color: #fff;
			border-color: transparent;
		}
	}

	&.btn-secondary {
		&.submit {
			background-color: #fc8c3f;
			color: #fff;
			border-color: transparent;
		}
	}


}

//personDiv selected option in select
.subform_firm_contact_entry_list {
	.select2-container .select2-selection--single .select2-selection__rendered {
		margin-left: 15px;
		margin-right: 25px;
		padding: 0;
	}

	.personDiv {
		display: flex;

		.personInfo {
			position: relative;
			width: 250px;
			overflow: hidden;
			display: inline-block;
			text-overflow: ellipsis;

			p.function {
				display: none;
			}
		}

		.personEditAction {
			display: none;
			float: right;
			margin-right: 13px;
			margin-top: 10px;

			i {
				padding: 5px 10px;
				font-size: 14px;
				right: 40px;
				top: -5px;
			}
		}
	}

	.contactList {
		position: relative;
		margin-top: -3px;

		.contactDiv {
			display: none;

			.contactDetail {
				position: relative;
				width: 250px !important;
				overflow: hidden;

			}

		}
	}

	.select2-selection__arrow, .select2-selection__clear {
		display: none;
	}

	.select2-selection__rendered, .select2-selection__rendered:hover {
		padding-left: 0;
		padding-right: 0;
		margin: 0 5px;
	}


	div.delete_field {
		width: 80px;
		margin-left: -86px;
		top: 6px;
	}
}

//personDiv in select dropdown
.select2-dropdown {
	div.personDiv {
		display: flex;
		justify-content: space-between;

		div.personEditAction {
			i {
				top: 2px;
				color: black;
			}
		}

	}

	div.contactDiv {
		display: flex;
		justify-content: space-between;
	}

	.select2-results__option--highlighted {
		div.personDiv {
			p.function {
				color: black;
			}
		}
	}
}

.ActionButton {
	display: none;
}

span.dateNextActionLayout {
	display: flex;
	justify-content: space-between;
	align-items: baseline;

	input {
		max-width: 45vw;
	}
}

@form-field-margin: 6px;
@form-field-input-padding: 6px 10px;
@form-field-input-border: 1px solid #ccc;
@form-field-border-color: #ccc;
@form-field-border-disabled-color: #e4e4e4;
@form-field-border-color-focus: #FF4E00;
@form-field-border-radius: 16px;
@form-field-color-disabled: #999;

/*
css checkbox and radio
*/
.styled-label {
	display: block;
}

// Radio- and checkboxes.
.styled-tick:extend(.styled-label) {
	padding-left: .1rem;

	input {
		transform: scale(1.5);
	}
}

span.mobileListFilter {
	position: fixed;
	top: 0;
	display: flex;
	justify-content: space-between;
	right: 0;
	color: #fff;
	z-index: 99;
	align-items: center;
	font-size: 1.5em;
	padding: .5em;

	a {
		color: #fff;

		&.active {
			color: orange;
		}
	}

	* {
		margin-left: 0.3em;
	}

	&.navBarHidden {
		display: none;
	}


}

.form-group.shareField {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.fSubmitSticky {
	button {
		height: 2em;
		margin: 3px;
		margin-left: 2rem;
		border-color: #000;
		padding-top: 3px;
	}

	div.submitInfo {
		display: none;
	}
}

.formContainer .fSubmitSticky {
	text-align: center;

	button {
		margin-left: 0;
		margin-right: 2rem;
	}
}

.btn-primary, .btn {
	&:active, &:focus {
		background-color: #fff !important;
		color: initial !important;
		border-color: initial !important;
		box-shadow: initial !important;
	}
}

.relationSummaryContainer {
	display: flex;
	height: auto;
	align-items: center;
	flex-wrap: wrap;
	margin-left: 0.2em;
	margin-right: 1em;

	.relationSummaryItem {
		flex: 0 0 100%;
		padding: 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1.2em;
		margin-bottom: 0.5rem;

		.relationName {
			display: flex;
			align-items: center;
			width: 50%;

			i {
				width: 25%;
				text-align: center;
			}
		}

		.relationCount {

		}
	}
}

.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-front {
	width: 100% !important;
	background-color: #fff !important;
	z-index: 9999 !important;
	overflow-x: scroll !important;
	height: 100% !important;
	position: fixed !important;
	top: 0 !important;

	&.user_message {
		position: fixed !important;
		width: auto !important;
		height: auto !important;
	}
}


label.permCheckIcon.ui-button.ui-widget.ui-state-default.ui-button-text-only.ui-state-active {
	background-color: orange;
}

.permCheckIcon.ui-button.ui-widget.ui-state-default.ui-button-text-only {
	border: 1px solid #b9b9b9;
	border-radius: 5px;
	padding: 0;
	font-size: 2em;
	flex: 1 1;
	text-align: center;
	justify-content: center;
}


//noinspection SpellCheckingInspection
.buttonset.ui-buttonset, .ui-controlgroup-horizontal {
	margin-right: 0 !IMPORTANT;
	display: flex;
	justify-content: space-around;

	label {
		&:focus {
			background-color: #fc8c3f !important;
			color: #fff !important;
			border-color: transparent !important;
		}

		&:active {
			background-color: #fc8c3f !important;
			color: #fff !important;
			border-color: transparent !important;
		}

		&.ui-state-active {
			background-color: #fc8c3f;
			color: #fff;
			border-color: transparent;


		}
	}

}

.ReactVirtualized__Grid__innerScrollContainer {
	overflow: visible !important;
}

.error_hint {
	//min-width: 100%;
	//min-height: 1em;
	//z-index: 1;
	//text-align: left;
	//color: red;
	//padding: 3px;

	min-width: 100%;
	min-height: 1em;
	z-index: 1;
	text-align: left;
	color: red;
	position: absolute;
	margin-top: 20px;
	left: 0 !important;
	//top: inherit !important;
	padding-left: 0.7em;

	.close {
		display: none;
		float: right;
		margin: -5px -5px 10px 10px;
		padding: 0;
		width: 16px;
		height: 16px;
		line-height: 14px;
		text-align: center;
		border: 1px solid transparent;
		border-radius: 4px;
		cursor: pointer;
		font-weight: bold;
		font-size: 14px;
	}

	&:before {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border: 10px solid transparent;
		border-right-color: #f8200d;
		top: 6px;
		left: -20px;
		z-index: 0;
	}

	.inner {
		padding: 0px;
		min-height: 16px;
		text-align: justify;
	}

	&.warning {
		background-color: #ffeacc;
		border-color: #f7960f;

		&:before {
			border-right-color: #f7960f;
		}
	}

	&.info {
		background-color: #ccffcc;
		border-color: #00ff00;

		&:before {
			border-right-color: #00ff00;
		}
	}

	.error {
		font-size: 0.9em;
		background-color: #ffcccc;
		padding: 5px;
		margin-top: 5px;
		border: 1px solid #ff0000;
	}

	.warning {
		font-size: 0.9em;
		background-color: #ffcccc;
		padding: 5px;
		margin-top: 5px;
		border: 1px solid #ff0000;
	}

	.info {
		font-size: 0.9em;
		background-color: #ccffcc;
		padding: 5px;
		margin-top: 5px;
		border: 1px solid #00ff00;
	}
}

.activeFilter {
	color: orangered;
	font-weight: 600;
	content: "Active";
	text-align: center;
}

.callFirm {
	font-size: 2.2em;
	color: #6fd76f;
	border: 1px solid transparent;
	border-radius: 50%;
	padding: .2em;
	box-shadow: 0 4px 6px 2px #ddd;
	float: right;
}

.mobileDialog, .mobileConfirmDialog {
	display: none;
	position: absolute;
	top: 50%;
	left: 10vw;
	background-color: #000;
	box-shadow: #000 2px 2px 2px;
	z-index: 99999999;
	margin: 0 auto;
	padding: 1em;
	font-size: 1.5em;
	border-radius: .3em;
	width: 80vw;
	text-align: center;
	justify-content: center;
	color: #fff;
	line-height: 1.5em;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cooperation_status_potential {
	color: @color-cooperation-status-potential;
}

.cooperation_status_active {
	color: @color-cooperation-status-active;
}

.cooperation_status_inactive {
	color: @color-cooperation-status-inactive;
}

.cooperation_status_finished {
	color: @color-cooperation-status-finished;
}

.cooperation_status_uninteresting {
	color: @color-cooperation-status-uninteresting;
}

.cooperation_status_never_contact {
	color: @color-cooperation-status-never-contact;
}

.noDataToShow, .activeFilter {
	//margin-top: 10vh;
	font-weight: 600;
	text-align: center;
}

.relationSummaryList {
	width: 100%;
	margin-bottom: 1rem;

	.summaryListItem {
		line-height: 1em;

		.itemFirm {
			//padding: .4em;
		}
	}

}

.callFirmWrapper {
	display: flex;
	width: 90%;
	justify-content: flex-end;
}

.maxRelContainer {
	width: 100%;
	display: block;
}

.minRelContainer {
	width: 100%;
	display: block;
}

.firmBasicBox {
	display: grid;

	.firmBoxRow {
		display: flex;
		padding: .3em;
		border-bottom: 1px solid #ddd;

		.boxRowTitle {
			width: 40%;
			display: flex;
			justify-content: flex-end;
			padding-inline-end: 1em;
			text-align: end;
			font-size: .9em;
			align-content: center;
		}

		.boxRowData {
			width: 60%;
			display: flex;
			justify-content: flex-start;
			text-align: start;
			font-size: .9em;
			align-content: center;
			font-weight: 600;
		}
	}
}

.tab-inactive {
	display: none;
}

.tab-active {
	display: block;
}

.ui-controlgroup-horizontal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.btn-secondary {
		flex: 1 1 auto;
		padding: 0;
		font-size: .8rem;
		font-weight: bold;
		line-height: 1.2rem;
		margin: 0.1rem;

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}
}

@import "../../MainMenu/MainMenu";
#mainMenu(@menuPrimaryBackgroundColor,
	@menuTextColor,
	@menuTopBarBottomBorderColor,
	@menuTopBarBackgroundColor,
	@menuIconDefaultColor,
	@menuIconActiveColor,
	@menuSectionDivider,
	@menuSectionHoverBackgroundColor,
	@menuSectionOpenBackgroundColor,
	@submenuDefaultTextColor,
	@submenuActiveTextColor,
	@submenuLineActiveColor,
	@submenuLineDefaultColor
);


//List item height

@listItemHeight: auto;

/////
@componentBackgroundColor: white;
@componentHeaderBackgroundColor: #eff2f4;
@componentHeaderBorderColor: #e5ebef;
@componentBorderColor: #d2d8dd;
@componentShadowColor: #e2e5e7;
@componentMargin: 1rem;
@pageBackgroundColor: #eff2f4;
@inlineClickableComponentColor: #1285de;
@activeTabBorder: #2f92df;
@inactiveTabColor: #929fa6;
@actionButtonColor: white;
@actionButtonBackgroundColor: #f89a1d;
@actionButtonShadowColor: rgba(0, 0, 0, .3);
@activeComponentBackground: #fff8c6;
@activeComponentBorder: 2px solid #f89a1d;
@oddRowBackgroundColor: #fff;
@evenRowBackgroundColor: #f7fafc;
@rowBorderColor: #e6ecef;
@iconBorderColor: #dfe4e8;
@containerBorder: 1px solid #dfe4e8;
@activeDropDownToggleBackgroundColor: #3b4549;
@activeDropDownToggleColor: white;
@menuTopBarBackgroundColor: #475155;
@menuTopBarBottomBorderColor: #566165;
@menuToggleIconColor: #1285de;
@menuTextColor: #fff;
@menuIconActiveColor: #1285de;
@menuIconDefaultColor: #748890;
@menuPrimaryBackgroundColor: #3b4549;
@menuSectionOpenBackgroundColor: #30393d;
@menuSectionHoverBackgroundColor: #30393d;
@menuSectionDivider: #444f54;
@submenuLineDefaultColor: #3b4549;
@submenuLineActiveColor: #f89a1d;
@submenuDefaultTextColor: #afb8bc;
@submenuActiveTextColor: #fff;
@menuUserDetailIconDefaultBackground: #f89a1d;
@btnPrimary: #f89a1d;

