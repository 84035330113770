//@import url(https://fonts.googleapis.com/css?family=Raleway:400,700,800);

*, *:after, *:before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	//height: 100%;
}

//body {
//	height: 100%;
//	background: #444;
//	color: #fffce1;
//	font-family: 'Raleway', Arial, sans-serif;
//}

#app {
	//height: 100%;
}

.menuButton{
	margin: -1em;
}

a {
	color: #4e4a46;
	text-decoration: none;

	&:hover,
	&:focus {
		color: #c94e50;
	}
}

main {
	//height: 100%;
	padding: 3em 2em;
	text-align: center;
	background: #b4bad2;
	overflow: auto;
}

h1 {
	font-weight: 800;
	font-size: 3.75em;
}

.description {
	max-width: 20em;
	margin: 1.2em auto 1em;
	line-height: 1.3em;
	font-weight: 400;
}

.demo-buttons {
	font-size: 1em;
	max-width: 1200px;
	margin: 2em auto 3em;
	padding: 0 10em;

	a {
		display: inline-block;
		margin: 0.75em;
		padding: 1.35em 1.1em;
		width: 15em;
		background: #fffce1;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 800;
		border-top-left-radius: 20px 50px;
		border-top-right-radius: 20px 50px;
		border-bottom-right-radius: 20px 50px;
		border-bottom-left-radius: 20px 50px;
		cursor: pointer;

		&.current-demo {
			background: #c94e50;
			color: #fffce1;
		}
	}
}

.side-button {
	display: inline-block;
	width: 5em;
	height: 2.5em;
	line-height: 2.5em;
	cursor: pointer;
	background: #fffce1;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 0.8em;
	font-weight: 800;

	&.left {
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}

	&.right {
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	&.active {
		background: #c94e50;
		color: #fffce1;
	}
}

@media screen and (max-width: 40em) {
	main {
		font-size: 80%;
	}

	h1 {
		padding-top: 1em;
		font-size: 2.5em;
	}

	.demo-buttons {
		max-width: 900px;
		padding: 0 2em;
	}
}

//
// Burger menu custom styles
//
.bm-burger-button {
	position: fixed;
	width: 36px;
	height: 30px;
	left: 36px;
	top: 36px;
}

.right .bm-burger-button {
	left: initial;
	right: 36px;
}

.bm-burger-bars {
	background: #373a47;
}

.bm-morph-shape {
	fill: #373a47;
}

.bm-menu {
	background: #f69230;
text-align: left;

	a {
		font-weight: 500;
		color: #000;
		border-bottom: 1px solid #d07523;
		display: block;
		&:hover,
		&:focus {
			color: #fff;
			background: #d07523;
		}
	}
}

.bm-item-list a {
	padding: 0.8em;

	span {
		margin-left: 10px;
		font-weight: 700;
	}
}

.bm-item:focus {
	outline: none;
}

//
// Mixins
//
.menu-1 {
	.bm-cross {
		background: #bdc3c7;
	}

	.bm-menu {
		padding: 2.5em 1.5em 0;
		font-size: 1.15em;
	}
}

.menu-2 {
	.bm-cross {
		background: #999;
	}

	.bm-menu {
		padding: 3.4em 1em 0;
	}

	a {
		padding: 1em;
	}

	i {
		font-size: 1.7em;
		vertical-align: middle;
		color: #282a35;
	}
}

.menu-3 {
	.bm-cross {
		background: #888;
	}

	.bm-menu {
		padding: 2em 1em;
		font-size: 1.15em;
	}

	i {
		opacity: 0.5;
	}

	span {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 0.75em;
	}
}

.menu-4 {
	.bm-cross {
		background: #888;
	}

	h2 {
		margin: 0 auto;
		padding: 2em 1em;
		color: rgba(0, 0, 0, 0.4);
		box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

		i {
			margin-left: 0.2em;
		}

		span {
			font-size: 1.6em;
			font-weight: 700;
		}
	}

	a {
		padding: 1em;
		text-transform: uppercase;
		transition: background 0.3s, box-shadow 0.3s;
		box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

		span {
			letter-spacing: 1px;
			font-weight: 400;
		}

		&:hover,
		&:focus {
			background: rgba(0, 0, 0, 0.2);
			box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
			color: #b8b7ad;
		}
	}
}

#slide,
#stack,
#reveal {
	.menu-1;
}

#bubble,
#push {
	.menu-2;
}

#elastic {
	.menu-3;
}

#scaleDown,
#scaleRotate,
#pushRotate,
#fallDown {
	.menu-4;
}