@import url("../../node_modules/@fortawesome/fontawesome-pro/css/all.min.css");
.fa.small {
  font-size: 8px;
}
.fa.white {
  color: #fff;
}
.fa.new {
  color: #41A042;
}
.fa-stack .pixeldown {
  margin-top: 1px;
}
