
$orange: #fc8c3f;
$primary: $orange;

$theme-colors: (
        primary: $orange
);
@import "~bootstrap/scss/bootstrap";
@import "~@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4";

