#mainMenu(@menuPrimaryBackgroundColor,
@menuTextColor,
@menuTopBarBottomBorderColor,
@menuTopBarBackgroundColor,
@menuIconDefaultColor,
@menuIconActiveColor,
@menuSectionDivider,
@menuSectionHoverBackgroundColor,
@menuSectionOpenBackgroundColor,
@submenuDefaultTextColor,
@submenuActiveTextColor,
@submenuLineActiveColor,
@submenuLineDefaultColor
) {
	.mainMenu {
		background: @menuPrimaryBackgroundColor;
		flex: 0 0 auto;
		z-index: 10;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		align-items: stretch;
		flex-direction: column;

		&.sticky {
			position: fixed;
		}

		& > div.menu {
			overflow: auto;
			max-height: calc(100vh - 3.7em);
			display: none;

			&.shown {
				display: initial;
			}

			& > ul {
				list-style: none;
				margin: 0;
				padding: 0;
				border-bottom: 0.1rem solid @menuSectionDivider;

				& > li {
					& > span:hover, & > a:hover {
						background: @menuSectionHoverBackgroundColor;
						cursor: pointer;
						text-decoration: none;
					}

					& > a, & > span {
						display: flex;
						justify-content: center;
						padding: 1rem;
						user-select: none;
						text-decoration: none;

						.icon {
							color: @menuIconDefaultColor;
							margin-right: 0.5rem;
							width: 1em;
						}

						span {
							display: block;
							width: 100%;
							overflow: hidden;
							white-space: nowrap;
							@media (min-width: 576px) {
								//transition: width 100ms;
							}
						}
					}

				}

				& > ul {
					list-style: none;
					margin: 0;
					padding: 0 0 1rem 0;
					position: relative;

					& > li {
						display: block;

						& > a {
							display: block;
							text-decoration: none;
							padding: 0.3rem 0.3rem 0.3rem 3rem;
							color: @submenuDefaultTextColor;
							position: relative;
							overflow: hidden;
							text-overflow: ellipsis;
							user-select: none;

							&:hover {
								color: @submenuActiveTextColor;

								&::before {
									background: @submenuLineActiveColor;
								}
							}

							&::before {
								position: absolute;
								background: @submenuLineDefaultColor;
								top: -0.01rem;
								width: 0.1rem;
								left: 1.6rem;
								height: 2rem;
								content: "";
							}
						}
					}
				}


				&.opened {
					background: @menuSectionOpenBackgroundColor;

					.icon {
						color: @menuIconActiveColor;
					}
				}
			}
		}

		&.compact {
			flex: 0 1 5rem;

			& > div.menu > ul {
				position: relative;

				& > li {

					& > a, & > span {
						& > span {
							width: 0;
						}

						& > .icon {
							margin: 0 0.4rem;
						}
					}
				}

				& > ul {
					position: absolute;
					background: @menuSectionOpenBackgroundColor;
					left: 5rem;
					top: 0;
					z-index: 1;
					padding: 0.7rem 0;

					& > li {
						width: 20rem;

						& > a:before {
							width: 0.15rem;
						}
					}
				}
			}
		}

		a, li {
			color: @menuTextColor;
		}

	}

	body.menuOpened {
		overflow: hidden;
	}
}